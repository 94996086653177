<template>
  <van-nav-bar :title="$t('profile')" :left-text="$t('back')" @click.left="$router.back()" />
  <div class="profile">
    <div class="header d-flex align-items-center">
      <van-image v-if="currentUser.avatar" round width="42px" height="42px" :src="currentUser.avatar" />
      <img v-else :src="icons.cube" />
      <span class="nickname">{{ currentUser.nickname ? currentUser.nickname : $t('Guest') }}</span>
    </div>
    <van-cell-group class="cell-group">
      <van-cell :title="$t('loginned_phone')" size="large" :value="fuzzMobile(currentUser.phone)">
        <template #icon>
          <van-icon :name="icons.phone" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
      <van-cell :title="$t('my_tickets')" size="large" is-link @click="$router.push({name: 'UserTicket'})">
        <template #icon>
          <van-icon :name="icons.ticket" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
      <van-cell :title="$t('my_collection')" size="large" is-link @click="$router.push({name: 'UserFavourite'})">
        <template #icon>
          <van-icon :name="icons.like" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="cell-group">
      <van-cell :title="$t('help')" size="large" is-link @click="$router.push({name: 'Help'})">
        <template #icon>
          <van-icon :name="icons.help" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
      <van-cell :title="$t('customer_service')" size="large" is-link @click="$router.push({name: 'Contact'})">
        <template #icon>
          <van-icon :name="icons.service" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
      <van-cell :title="$t('about_xvr')" size="large" is-link @click="$router.push({name: 'About'})">
        <template #icon>
          <van-icon :name="icons.logo" size="24px" class="van-cell__left-icon" />
        </template>
      </van-cell>
    </van-cell-group>
    <van-button type="danger" plain block style="border-radius: 20px;" @click="logout">{{ $t('logout') }}</van-button>
    <van-tabbar v-model="activeTab">
      <van-tabbar-item name="home" icon="coupon-o" @click="$router.push({ name: 'Home' })">{{ $t('appname') }}</van-tabbar-item>
      <van-tabbar-item name="user" icon="user-o">{{ $t('profile') }}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { fuzzMobile } from "@/utils/util";
import { PURGE_AUTH } from "@/store/modules/auth";
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      activeTab: 'user'
    });

    const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));

    const icons = {
      cube: require("@/assets/images/icons/cube.svg"),
      phone: require("@/assets/images/icons/phone.svg"),
      ticket: require("@/assets/images/icons/ticket.svg"),
      like: require("@/assets/images/icons/like.svg"),
      help: require("@/assets/images/icons/help.svg"),
      service: require("@/assets/images/icons/service.svg"),
      logo: require("@/assets/images/icons/logo.svg"),
    };

    const logout = () => {
      store.commit(PURGE_AUTH);
      window.location.href = "/";
    };

    onMounted(() => {
      if (!currentUser || !currentUser.access_token) {
        window.location.href = "/password-login";
      }
    });

    return {
      ...toRefs(state),
      icons,
      currentUser,
      fuzzMobile,
      logout
    }
  }
}
</script>

<style lang="less" scoped>
.profile {
  background-color: white;
  padding: 0 20px;

  .header {
    margin-top: 30px;

    .nickname {
      margin-left: 10px;
    }
  }

  .cell-group {
    margin: 40px 0;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.1);
  }
}
</style>